export const environment = {
  production: true,
  socialLogin:'https://snapeqatar.auth.us-east-2.amazoncognito.com/login?response_type=token&client_id=7i6uo8u8d9s8njhkdq04sihnoa&redirect_uri=https://snapecookies.com/',
  initiate: 'https://mxwfi8nbjj.execute-api.us-east-2.amazonaws.com/Prod',
  execute: 'https://g2b4s4dmg3.execute-api.us-east-2.amazonaws.com/Prod',
  callBack: "https://snapecookies.com/order-successful",
  token:'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/fcmtoken_update',
  firebase: {
    apiKey: "AIzaSyBsKkezOqHChiTg05tOXbqEwc7r75dMeGk",
    authDomain: "snape-cookies.firebaseapp.com",
    databaseURL: "https://snape-cookies-default-rtdb.firebaseio.com",
    projectId: "snape-cookies",
    storageBucket: "snape-cookies.appspot.com",
    messagingSenderId: "903439747243",
    appId: "1:903439747243:web:453c62b9ecf946cbc94fee",
    measurementId: "G-K6BNWYJGGP"
  },
  baseUrl: 'https://39xj4qskj7.execute-api.ap-south-1.amazonaws.com/',
  signUp: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/user',
  verifyEmail: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/email-confirmation ',
  verifyNumber: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/email-confirmation',
  resendCodesignup: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/resend-code',
  resendCode:'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/forget-password',
  signIn: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/signin',
  userInfo: 'https://snapeqatar.auth.us-east-2.amazoncognito.com/oauth2/userInfo',
  forgetPassword: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/forget-password',
  confirmPassword: 'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/confirm-password',
  userProfile: "https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/user ",
  address: 'https://8nuzz9wex0.execute-api.us-east-2.amazonaws.com/prod/address',
  listZone: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/listzones',
  favourite: 'https://zg8eoeiiz2.execute-api.us-east-2.amazonaws.com/prod/favourite',
  templates: 'https://dng7c1zt1j.execute-api.us-east-2.amazonaws.com/prod/templates',
  userProducts: 'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/prod/user-products',
  faq: 'https://ri09cq2uob.execute-api.us-east-2.amazonaws.com/prod/',
  flavour: 'https://7sbe4r5nb0.execute-api.us-east-2.amazonaws.com/prod/flavour ',
  cart: 'https://bqeejq5z3b.execute-api.us-east-2.amazonaws.com/prod/cart',
  imageUpload: 'https://6cn2qm94lj.execute-api.us-east-2.amazonaws.com/image-upload/snape-cookie?file=',
  s3bucket: 'https://snape-cookie.s3.us-east-2.amazonaws.com/',
  orders: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/orders',
  trackOrder: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/orders/tracking?id=',
  orderDetails: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/orders/order-details?id=',

  deliveryStatus: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/change-delivery-status',

  payment: 'https://0qls6s66y0.execute-api.us-east-2.amazonaws.com/prod/payment',
  chat: 'https://evngndsua9.execute-api.us-east-2.amazonaws.com/prod/',

  message: 'https://evngndsua9.execute-api.us-east-2.amazonaws.com/prod/?id=',

  // notification: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/notifications/',
  notification: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/prod/',
  batchDelete: 'https://z2i19t7ici.execute-api.us-east-2.amazonaws.com/notifications/user-batch-delete',
  contactUs: 'https://puq9chx22m.execute-api.us-east-2.amazonaws.com/prod/contact-us ',
  orderById: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/orders/order-details?id=',

  itemStatus: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/orders/item-status',
  orderStatus: 'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/orders/order-status',

  newsLetter: 'https://0ir44ib3pb.execute-api.us-east-2.amazonaws.com/prod/news-letter ',
  getAdmin: 'https://l9jbttxtrj.execute-api.us-east-2.amazonaws.com/prod/getadmin',
  orderCancel:'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/ordercancellation',
  getCoupon:'https://7ra3dvu8p4.execute-api.us-east-2.amazonaws.com/prod/couponcodediscount?couponCode=',
  otpLogin:'https://1h1uzqodw3.execute-api.us-east-2.amazonaws.com/prod/signin_otpverification',
  boxcolor:'https://8ihex1qk65.execute-api.us-east-2.amazonaws.com/dev/boxcolorupload'
};
